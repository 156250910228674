import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-hayward-california.png'
import image0 from "../../images/cities/scale-model-of-patterson-house-in-hayward-california.png"
import image1 from "../../images/cities/scale-model-of-deep-creek-park-in-hayward-california.png"
import image2 from "../../images/cities/scale-model-of-hayward-japanese-gardens-in-hayward-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Hayward'
            state='California'
            image={image}
            lat='37.6688205'
            lon='-122.0807964'
            attractions={ [{"name": "Patterson House", "vicinity": "34600 Ardenwood Blvd, Fremont", "types": ["museum", "point_of_interest", "establishment"], "lat": 37.5579507, "lng": -122.04870840000001}, {"name": "Deep Creek Park", "vicinity": "Fremont", "types": ["park", "point_of_interest", "establishment"], "lat": 37.5732546, "lng": -122.05426940000001}, {"name": "Hayward Japanese Gardens", "vicinity": "22373 N 3rd St, Hayward", "types": ["park", "point_of_interest", "establishment"], "lat": 37.67874219999999, "lng": -122.07985280000003}] }
            attractionImages={ {"Patterson House":image0,"Deep Creek Park":image1,"Hayward Japanese Gardens":image2,} }
       />);
  }
}